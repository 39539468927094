// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import "./newport.css";
// function NewNav() {
//     const [isOpen, setIsOpen] = useState(false);

//     const toggleMenu = () => {
//         setIsOpen(!isOpen);
//     };

//     const closeMenu = () => {
//         setIsOpen(false);
//     };

//     useEffect(() => {
//         if (isOpen) {
//             document.body.classList.add("no-scroll");
//         } else {
//             document.body.classList.remove("no-scroll");
//         }
//     }, [isOpen]);

//     return (
//         <div className="newNav">
//             <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-aut">
//                 <a
//                     href="https://flowbite.com/"
//                     class="flex items-center space-x-3 rtl:space-x-reverse"
//                 >
//                     <img
//                         src={`${process.env.PUBLIC_URL}/images/newlogo.png`}
//                         className="newLogo"
//                     />
//                 </a>
//                 <ul className="newHomeLink">
//                     <li className="navigations transition-all duration-300 hover:ml-2">
//                         <Link to="/newHome" onClick={closeMenu}>
//                             .Home()
//                         </Link>
//                     </li>
//                 </ul>
//                 <ul className="newAboutLink">
//                     <li className="navigations transition-all duration-300 hover:ml-2">
//                         <Link to="/newAboutMe" onClick={closeMenu}>
//                             .About()
//                         </Link>
//                     </li>
//                 </ul>
//                 <ul className="newContactLink">
//                     <li className="navigations transition-all duration-300 hover:ml-2">
//                         <Link to="/newContact" onClick={closeMenu}>
//                             .Contact()
//                         </Link>
//                     </li>
//                 </ul>
//                 <ul className="newProjectsLink">
//                     <li className="navigations transition-all duration-300 hover:ml-2">
//                         <Link to="/newProjects" onClick={closeMenu}>
//                             .Projects()
//                         </Link>
//                     </li>
//                 </ul>
//             </div>
//         </div>
//     );
// }

// export default NewNav;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./newport.css";

function NewNav() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isOpen]);

    return (
        <div className="newNav">
            <header className="flex flex-wrap sm:justify-start sm:flex-nowrap w-full text-sm">
                <nav className="max-w-[87rem] w-full mx-auto sm:flex sm:items-center sm:justify-between relative">
                    {/* Logo */}
                    <Link
                        to="/newHome"
                        className="flex items-center space-x-3 rtl:space-x-reverse"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/images/newlogo.png`}
                            className="newLogo"
                            alt="Logo"
                        />
                    </Link>

                    {/* Hamburger Menu Icon (Visible Only on Mobile) */}
                    <div
                        className="hamburger-menu sm:hidden"
                        onClick={toggleMenu}
                        aria-label="Toggle Menu"
                    >
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>

                    {/* Navigation Links */}
                    <div
                        className={`navigation-links ${
                            isOpen ? "open" : ""
                        } sm:flex sm:flex-row sm:items-center`}
                    >
                        <ul className="newHomeLink">
                            <li className="navigations transition-all duration-300 hover:ml-2 hover:mr-2">
                                <Link to="/newHome" onClick={closeMenu}>
                                    .Home()
                                </Link>
                            </li>
                        </ul>
                        <ul className="newAboutLink">
                            <li className="navigations transition-all duration-300 hover:ml-2 hover:mr-2">
                                <Link to="/newAboutMe" onClick={closeMenu}>
                                    .About()
                                </Link>
                            </li>
                        </ul>
                        <ul className="newContactLink">
                            <li className="navigations transition-all duration-300 hover:ml-2 hover:mr-2">
                                <Link to="/newContact" onClick={closeMenu}>
                                    .Contact()
                                </Link>
                            </li>
                        </ul>
                        <ul className="newProjectsLink">
                            <li className="navigations transition-all duration-300 hover:ml-2 hover:mr-2">
                                <Link to="/newProjects" onClick={closeMenu}>
                                    .Projects()
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default NewNav;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function NewProjectsDesign() {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const [isDesktop, setIsDesktop] = useState(false);

    // Check screen size
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768); // Set breakpoint for desktop
        };

        handleResize(); // Check initially
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="newProjectsDesigns">
            <h2 className="headingInProjectsDesigns ml-12">Designs</h2>

            <motion.div
                ref={ref}
                className="firstProjDesignDiv"
                initial={isDesktop ? { opacity: 0 } : {}}
                animate={isDesktop && inView ? { opacity: 1 } : {}}
                transition={{ duration: 1 }}
            >
                <img
                    className="firstProjDesign"
                    src={`${process.env.PUBLIC_URL}/images/firstProjDesign.png`}
                    alt="Design Preview"
                />
            </motion.div>

            <motion.div
                ref={ref}
                className="secondProjDesignDiv"
                initial={isDesktop ? { opacity: 0 } : {}}
                animate={isDesktop && inView ? { opacity: 1 } : {}}
                transition={{ duration: 1 }}
            >
                <img
                    className="secondProjDesign"
                    src={`${process.env.PUBLIC_URL}/images/secondProjDesign.png`}
                    alt="Design Preview"
                />
            </motion.div>

            <motion.div
                ref={ref}
                className="thirdProjDesignDiv"
                initial={isDesktop ? { opacity: 0 } : {}}
                animate={isDesktop && inView ? { opacity: 1 } : {}}
                transition={{ duration: 1 }}
            >
                <img
                    className="thirdProjDesign"
                    src={`${process.env.PUBLIC_URL}/images/thirdProjDesign.png`}
                    alt="Design Preview"
                />
            </motion.div>

            <motion.div
                ref={ref}
                className="fourthProjDesignDiv"
                initial={isDesktop ? { opacity: 0 } : {}}
                animate={isDesktop && inView ? { opacity: 1 } : {}}
                transition={{ duration: 1 }}
            >
                <img
                    className="fourthProjDesign"
                    src={`${process.env.PUBLIC_URL}/images/fourthProjDesign.png`}
                    alt="Design Preview"
                />
            </motion.div>
        </div>
    );
}

export default NewProjectsDesign;

import React from "react";
import NewProjectsDesign from "./NewProjectsDesign";
function NewProjects() {
    return (
        <div>
            <div className="newProjectsMotherDiv">
                <h2 className="headingInProjects ml-12">Projects</h2>
                <div className="newFirstProject">
                    <a
                        href="https://easyday.vercel.app"
                        className="text-center block hover:text-blue-500 transition duration-300"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h1 className="newProjectTextClothing text-center">
                            Clothing Store
                        </h1>
                    </a>
                </div>
                <div className="newSecondProject">
                    <a
                        href="https://rainbirdscakes.co.nz"
                        className="text-center block hover:text-blue-500 transition duration-300"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h1 className="newProjectTextCake text-center">
                            Cake Booking Website
                        </h1>
                    </a>
                </div>
                <div className="newThirdProject">
                    <h1 className="newProjectTextEmbed text-center">
                        Embedded Systems
                    </h1>
                </div>

                <div className="newFourthProject">
                    <a
                        href="https://github.com/Stpnadi/GameDev_BibikoJump"
                        className="text-center block hover:text-blue-500 transition duration-300"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h1 className="newProjectText2d text-center">
                            2D Platformer
                        </h1>
                    </a>
                </div>
                <div className="newFifthProject">
                    <a
                        href="https://github.com/iAmJiro/Server"
                        className="text-center block hover:text-blue-500 transition duration-300"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h1 className="newProjectTextc2s text-center">
                            Client to Server
                        </h1>
                    </a>
                </div>
                <div className="newSixthProject">
                    <a
                        href="Final Jade Project.zip"
                        download
                        className="text-center block hover:text-blue-500 transition duration-300"
                    >
                        <h1 className="newProjectTextFlight text-center">
                            Flight Booking
                        </h1>
                    </a>
                </div>
            </div>
            <NewProjectsDesign />
        </div>
    );
}
export default NewProjects;
